<template>
    <v-main>
        <AppBar></AppBar>
        <router-view></router-view>
        <BottomNavigation></BottomNavigation>
    </v-main>
</template>

<script>
import { onBeforeMount } from 'vue';
import { useAppStore } from '@/stores/app';
import BottomNavigation from '@/components/BottomNavigation.vue';
import AppBar from '@/components/AppBar.vue';

export default {
    name: 'IndexPage',
    components: {
        BottomNavigation,
        AppBar

    },
    setup() {
        const appStore = useAppStore();

        onBeforeMount(async () => {
            await appStore.fetchMarketData('666250df36b82aa37072fba2');
        });

        return {
            marketData: appStore.getMarketData,
        };
    },
};
</script>

<style></style>