<template>
    <v-container v-if="marketData && !getshowSearch"  class="pa-0 ma-0" style="max-width: auto;">
        <v-row class="justify-center">
            <v-col cols="12" class="text-center">
                <v-avatar size="150" class="mx-auto mb-4">
                    <v-img :src="apiUrl + marketData.info.logo" alt="Logo"></v-img>
                </v-avatar>
                <h1 class="text-h4 font-weight-bold">{{ marketData.info.name }}</h1>
                <v-divider class="my-4"></v-divider>
                <h2 class="text-h5 font-weight-bold mb-4">تستطيع التواصل معنا من خلال:</h2>
            </v-col>
        </v-row>
        <v-row class="justify-center d-flex justify-center">
            <v-col v-if="marketData.info.social_links.facebook" cols="12" md="6" class="d-flex justify-center mb-4">
                <v-btn :href="marketData.info.social_links.facebook" target="_blank" icon class="ma-2">
                    <v-icon color="primary" large>mdi-facebook</v-icon>
                </v-btn>
                <a :href="marketData.info.social_links.facebook" target="_blank" class="text-decoration-none">
                    <v-sheet class="ma-2 pa-2 align-self-center text-h6 font-weight-bold"  style="background: none;">Facebook</v-sheet>
                </a> 
               
            </v-col>
            <v-col v-if="marketData.info.social_links.instagram" cols="12" md="6" class="d-flex justify-center mb-4">
                
                <v-btn :href="marketData.info.social_links.instagram" target="_blank" icon class="ma-2">
                    <v-icon color="pink-lighten-1" large>mdi-instagram</v-icon>
                </v-btn>
                <a :href="marketData.info.social_links.instagram" target="_blank" class="text-decoration-none">
                    <v-sheet class="ma-2 pa-2 align-self-center text-h6 font-weight-bold"  style="background: none;" >Instagram</v-sheet>

                </a>
            </v-col>

            <v-col v-if="marketData.info.social_links.telegram" cols="12" md="6" class="d-flex justify-center mb-4">
                <v-btn :href="marketData.info.social_links.telegram" target="_blank" icon class="ma-2">
                    <v-icon color="blue-lighten-1" large>mdi-send</v-icon>
                </v-btn>
                <a :href="marketData.info.social_links.telegram" target="_blank" class="text-decoration-none">
                    <v-sheet class="ma-2 pa-2 align-self-center text-h6 font-weight-bold"  style="background: none;">Telegram</v-sheet>
                </a>
            </v-col>

            <v-col v-if="marketData.info.social_links.whatsapp" cols="12" md="6" class="d-flex justify-center mb-4">
                <v-btn :href="marketData.info.social_links.whatsapp" target="_blank" icon class="ma-2">
                    <v-icon color="teal-accent-3" large>mdi-whatsapp</v-icon>
                </v-btn>
                <a :href="marketData.info.social_links.whatsapp" target="_blank" class="text-decoration-none">
                <v-sheet class="ma-2 pa-2 align-self-center text-h6 font-weight-bold"   style="background: none;">Whatsapp</v-sheet>
                </a>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-if="marketData && getshowSearch" class="fill-height pa-0">
        <v-row >
                <v-col v-for="product in getfilteredProducts" :key="product.id" cols="12" class="product-item">
                    <v-row>
                        <v-col cols="4">
                            <v-img :src="apiUrl + product.image" alt="Product Image" width="100%" height="100px"></v-img>
                        </v-col>
                        <v-col cols="8">
                            <div class="product-name">{{ getLocalizedText(product, 'name') }}</div>
                            <div class="product-description">{{ getLocalizedText(product, 'description') }}</div>
                            <div class="product-price-wrapper">
                                <div class="product-price">IQD {{ product.price }}</div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
    </v-container>
</template>

<script setup>


</script>

<script>
import { useAppStore } from '@/stores/app';

export default {
    name: 'AboutPage',

    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL + '/',
            appStore: useAppStore(),
        };
    },

    computed: {
        marketData() {
            return this.appStore.getMarketData;
        },
        getshowSearch(){
            return this.appStore.showSearch;
        },
        getfilteredProducts(){
            return this.appStore.finalfilteredProducts;
        }
    },
    methods:{
        getLocalizedText(item, key) {
            switch (key) {
                case 'name':
                    return localStorage.getItem('locale') === 'ar' ? item.name_ar : item.name_en;
                case 'description':
                    return localStorage.getItem('locale') === 'ar'
                        ? item.description_ar
                        : item.description_en;
                default:
                    return '';
            }
        }
    }
};
</script>

<style>
/* Add any necessary styles here */
</style>
