/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
import pinia from '@/stores'
import router from '@/router'


import { createI18n } from 'vue-i18n';
import ar from '../locales/ar';
import en from '../locales/en';

const messages = {
  ar,
  en,
};
if(    localStorage.getItem('locale') == undefined){
    localStorage.setItem('locale', 'ar');

}
const savedLocale = localStorage.getItem('locale') || 'ar';

const i18n = createI18n({
    legacy: false,
  locale: savedLocale, // اللغة الافتراضية
  fallback: 'en',
  fallbackLocale: 'en',
  messages,
});

export function registerPlugins (app) {
  app
    .use(vuetify)
    .use(i18n)
    .use(router)
    .use(pinia)
}
