<template>
    <v-app>
      <v-main class="bodyhome">
        <v-container v-if="isDataLoaded && showLogo" class="d-flex justify-center align-center" style="height: 100vh;">
          <v-img :src="apiUrl + marketData.info.logo" alt="Store Logo" max-width="300" />
        </v-container>
        <router-view v-else />
      </v-main>
    </v-app>
  </template>
  
  <script>
  import { ref, onMounted, computed } from 'vue';
  import { useAppStore } from './stores/app';
  
  export default {
    name: 'App',
    setup() {
      const appStore = useAppStore();
      const apiUrl = process.env.VUE_APP_API_URL + '/';
      const showLogo = ref(true);
      const isDataLoaded = ref(false); // البداية تكون false
  
      onMounted(async () => {
        await appStore.fetchMarketData('666250df36b82aa37072fba2');
        isDataLoaded.value = true; // البيانات تم تحميلها
        
        // الانتظار 5 ثوانٍ ثم إخفاء الشعار
        setTimeout(() => {
          showLogo.value = false;
        }, 2000);
      });
  
      const marketData = computed(() => appStore.getMarketData);
  
      return {
        apiUrl,
        showLogo,
        isDataLoaded,
        marketData,
      };
    },
  };
  </script>
  
  <style>
  .bodyhome {
    background-color: #f5f5f5;
  }
  </style>
  