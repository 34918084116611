<template>
    <v-container v-if="marketData && !getshowSearch" class="fill-height pa-0">
        <v-responsive class="align-center fill-height mx-auto">
            <!-- Slider  -->
            <v-row class="px-4 ma-0" justify="center">
                <v-col cols="12">
                    <!-- Carousel for larger screens -->
                    <v-responsive max-height="300px" class="hidden-sm-and-down">
                        <v-carousel height="100%" hide-delimiters cycle show-arrows="hover">
                            <v-carousel-item class="rounded-xl" v-for="slider in marketData.sliders" :key="slider.id"
                                cover>
                                <v-img :src="apiUrl + slider.image" alt="Slider Image"
                                    class="fill-height rounded-xl w-full" max-height="225" cover
                                    aspect-ratio="1"></v-img>
                            </v-carousel-item>
                        </v-carousel>
                    </v-responsive>

                    <!-- Carousel for mobile screens -->
                    <v-responsive class="hidden-md-and-up">
                        <v-carousel height="100%" hide-delimiters cycle show-arrows="hover">
                            <v-carousel-item class="rounded-xl" v-for="slider in marketData.sliders" :key="slider.id"
                                cover>
                                <v-img :src="apiUrl + slider.image" alt="Slider Image"
                                    class="fill-height rounded-xl"></v-img>
                            </v-carousel-item>
                        </v-carousel>
                    </v-responsive>
                </v-col>
            </v-row>

            <div>
                
            </div>
            <!-- Categories Section -->
        <v-row class="pa-1 mb-4 ">
                <v-slide-group class="my-4 mb-4">
                    <v-slide-group-item v-for="category in marketData.categories" :key="category.id">
                        <v-card
                            :class="['mx-3  mb-2 rounded-lg', { 'selected': selectedCategory === category.id, 'noSelected': selectedCategory !== category.id }]"
                            @click="selectCategory(category.id)" outlined>
                            <template v-slot:actions>
                                <div class="category-item">
                                    <v-img :src="apiUrl + category.image" alt="Category Image"
                                        class="category-image"></v-img>
                                    <div
                                        :class="{ 'selected-text': selectedCategory === category.id, 'default-text': selectedCategory !== category.id }">
                                        {{ getLocalizedText(category, 'name') }}
                                    </div>
                                </div>
                            </template>
                        </v-card>
                    </v-slide-group-item>
                </v-slide-group>

            </v-row> 

            <!-- Products based on selected category -->
        <v-row>
                <v-col cols="6" class="mx-2 align-center pt-6">
                    <h3>{{ $t('sortItem') }}</h3>
                </v-col>
                <v-col cols="5" class="d-flex justify-end align-end">
                    <v-btn icon @click="toggleLayout">
                        <v-icon>{{ layoutIcon }}</v-icon>
                    </v-btn>
                </v-col>
            </v-row> 
           <v-row v-if="singleProduct" class="pa-4">
                <v-col v-for="product in filteredProducts" :key="product.id" cols="12" class="product-item">
                    <v-row>
                        <v-col cols="4">
                            <v-img :src="apiUrl + product.image" alt="Product Image" width="100%"
                                height="100px"></v-img>
                        </v-col>
                        <v-col cols="8">
                            <div class="product-name">{{ getLocalizedText(product, 'name') }}</div>
                            <div class="product-description">{{ getLocalizedText(product, 'description') }}</div>
                            <div class="product-price-wrapper">
                                <div v-if="product.single_price == 'true'" class="product-price">IQD {{ product.price }}
                                </div>
                                <div v-else class="product-price  d-flex justify-between ">
                                    (S){{ product.price_s }} ||
                                    (M){{ product.price_m }} ||
                                    (L){{ product.price_l }}
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row> 
            <v-row v-if="!singleProduct" class="pa-4">
                <v-col v-for="product in filteredProducts" :key="product.id" cols="6"
                    class="product-item d-flex flex-column">
                    <v-row class="">
                        <v-col cols="12" class="d-flex justify-center">
                            <v-img :src="apiUrl + product.image" alt="Product Image" height="150px"></v-img>
                        </v-col>
                        <v-col cols="12" class=" justify-center align-center">
                            <div class="product-name">{{ getLocalizedText(product, 'name') }}</div>
                            <div class="product-description">{{ getLocalizedText(product, 'description') }}</div>
                            <div class="product-price-wrapper">
                                <div v-if="product.single_price == 'true'" class="product-price">IQD {{ product.price }}
                                </div>
                                <div v-else class="product-price  d-flex justify-between">
                                    <p>(S){{ product.price_s }}</p>
                                    <p>(M){{ product.price_m }}</p>
                                    <p>(L){{ product.price_l }}</p>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-responsive>
    </v-container>
    <v-container v-if="marketData && getshowSearch" class="fill-height pa-0">
        <v-row>
            <v-col v-for="product in getfilteredProducts" :key="product.id" cols="12" class="product-item">
                <v-row>
                    <v-col cols="3">
                        <v-img :src="apiUrl + product.image" alt="Product Image" width="100%" height="100px"></v-img>
                    </v-col>
                    <v-col cols="9">
                        <div class="product-name">{{ getLocalizedText(product, 'name') }}</div>
                        <div class="product-description">{{ getLocalizedText(product, 'description') }}</div>
                        <div class="product-price-wrapper">

                            <div class="product-price">IQD {{ product.price }}</div>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useAppStore } from '@/stores/app';

export default {
    name: 'HomePage',
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL + '/',
            appStore: useAppStore(),
            singleProductLayout: true,
            layoutIcon: 'mdi-view-list',
            singleProduct: true,
            filteredProducts: [],
            selectedCategory: null
        };
    },
    computed: {
        marketData() {
            return this.appStore.getMarketData;
        },
        getshowSearch() {
            return this.appStore.showSearch;
        },
        getfilteredProducts() {
            return this.appStore.finalfilteredProducts;
        }
    },
    methods: {
        toggleLayout() {
            this.singleProductLayout = !this.singleProductLayout;
            this.singleProduct = !this.singleProduct;
            this.layoutIcon = this.singleProductLayout ? 'mdi-view-list' : 'mdi-view-module';
        },
        selectCategory(categoryId) {
            this.selectedCategory = categoryId;
            this.filterProductsByCategory();
        },
        filterProductsByCategory() {
            if (this.marketData && this.marketData.products) {
                this.filteredProducts = this.marketData.products.filter(product => product.categorie_id === this.selectedCategory).sort((a, b) => a.sort_order - b.sort_order);
                console.log(this.filteredProducts);

            }
        },
        getLocalizedText(item, key) {
            switch (key) {
                case 'name':
                    return localStorage.getItem('locale') === 'ar' ? item.name_ar : item.name_en;
                case 'description':
                    return localStorage.getItem('locale') === 'ar'
                        ? item.description_ar
                        : item.description_en;
                default:
                    return '';
            }
        }
    },
    watch: {
        marketData(newVal) {
            if (newVal && newVal.categories.length > 0 && !this.selectedCategory) {
                this.selectedCategory = newVal.categories[0].id;
            }
            this.filterProductsByCategory();
        }
    },
    mounted() {
        if (this.marketData && this.marketData.categories.length > 0) {
            this.selectedCategory = this.marketData.categories[0].id;
        }
        this.filterProductsByCategory();
    }
};
</script>


<style>
.category-item {
    text-align: center;
    margin: 0 18px;
    padding-bottom: 5px;
}

.category-avatar {
    width: 105px;
    height: 105px;
    border-radius: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.category-image {
    width: 54px;
    height: 54px;
    object-fit: cover;
}

.selected {
    background-color: #eb3254;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 8px 0px;
}

.noSelected {
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 8px 0px;
}

.default {
    background-color: white;
}

.selected-text {
    color: white;
}

.default-text {
    color: #eb3254;
}

.selected-category-info {
    text-align: center;
    margin-top: 20px;
}

.product-item {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 8px;
}

.product-image {
    width: 100%;
    height: 64px;
    object-fit: cover;
    border-radius: 8px;
}

.product-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
}

.product-description {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #6b6b6b;
}

.product-price-wrapper {
    display: flex;
    justify-content: flex-start;
}

.product-price {
    background-color: #eb3254;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
}

.sticky-categories {
    position: sticky;
    top: 0;
    z-index: 2;
}
</style>
