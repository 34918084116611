<template>
        <v-container v-if="marketData && !getshowSearch" class=" fill-height pa-0" >


            <v-row>
                <v-col>
                    <v-card text
    class="mx-auto px-5"   
    variant="text" 
  >
    <template v-slot:title>
      <span class=" text-h2 font-weight-black">من نحن؟</span>
    </template>

    <v-card-text class="text-h6 text-justify pt-4 ">
        {{ marketData.info.description }}    </v-card-text>
  </v-card>
                 
                </v-col>
      
            </v-row>
        </v-container>
        <v-container v-if="marketData && getshowSearch" class="fill-height pa-0">
        <v-row >
                <v-col v-for="product in getfilteredProducts" :key="product.id" cols="12" class="product-item">
                    <v-row>
                        <v-col cols="4">
                            <v-img :src="apiUrl + product.image" alt="Product Image" width="100%" height="100px"></v-img>
                        </v-col>
                        <v-col cols="8">
                            <div class="product-name">{{ getLocalizedText(product, 'name') }}</div>
                            <div class="product-description">{{ getLocalizedText(product, 'description') }}</div>
                            <div class="product-price-wrapper">
                                <div class="product-price">IQD {{ product.price }}</div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
    </v-container>
</template>



<script>
import { useAppStore } from '@/stores/app';

export default {
    name: 'AboutPage',

    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL + '/',
            appStore: useAppStore(),
        };
    },

    computed: {
        marketData() {
            return this.appStore.getMarketData;
        },
        getshowSearch(){
            return this.appStore.showSearch;
        },
        getfilteredProducts(){
            return this.appStore.finalfilteredProducts;
        }
    },
    methods:{
        getLocalizedText(item, key) {
            switch (key) {
                case 'name':
                    return localStorage.getItem('locale') === 'ar' ? item.name_ar : item.name_en;
                case 'description':
                    return localStorage.getItem('locale') === 'ar'
                        ? item.description_ar
                        : item.description_en;
                default:
                    return '';
            }
        }
    }
};
</script>

<style>
/* Add any necessary styles here */
</style>
