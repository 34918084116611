// Utilities
import { defineStore } from 'pinia'
import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
    headers: {
      'Content-Type': 'application/json'
    }
  });
export const useAppStore = defineStore('app', {
    state: () => ({
        marketData: null,
        showSearch: false,
        searchQuery: '',
        finalfilteredProducts: []
      }),
      actions: {
        async fetchMarketData(marketId) {
          try {
            const response = await apiClient.get(`/markets/${marketId}`);
            this.marketData = response.data;
          } catch (error) {
            console.error("Failed to fetch market data:", error);
          }
        },
        setShowSearch(value) {
            this.showSearch = value;
        },
        setSearchQuery(query) {
            this.searchQuery = query;
        },
        setFilteredProducts(products) {
            this.finalfilteredProducts = products;
        },
      },
      getters: {
        getMarketData: (state) => state.marketData,
      },
    })