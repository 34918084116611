import HomePage from '../pages/Home.vue'
import connectPage from '../pages/connect.vue'
import AboutPage from '../pages/about.vue'
import indexPage from '../pages/index.vue'

const menuRoutes = [

{
    path: '/',
    redirect: '/home',
    name: 'index',
    component: indexPage,
    children: [
        {
            path: '/home',
            name: 'home',
            component: HomePage
          },
          {
            path: '/connect',
            name: 'connect',
            component: connectPage
          },
          {
            path: '/about',
            name: 'about',
            component: AboutPage
          },
      
    ]
},

 

]

export default menuRoutes;
