const en = {

    home: "Home",
    category:"Category",
    info:"Info",
    order:"Order",
    searchProduct:"Search product",
    sortItem:"Sort Item",
    connectUs:"Connect With Us",

    pagination: {
      ariaLabel: {
        root: "Root",
      },
    },
    input:{
        clear:"Clear",
        prependAction:"prepend Action",
        appendAction:"append Action",
    },
    carousel:{
        next:"next",
        prev:"prev",

    }
  };
  
  export default en;
  