const ar = {

    
    home: "الرئيسية",
    category:"category",
    info:"من نحن",
    order:"order",
    searchProduct:"بحث عن منتج",
    sortItem:"ترتيب العناصر",
    connectUs:"تواصل معنا",
    pagination: {
      ariaLabel: {
        root: "الجذر",
      },
    },
    input:{
        clear:"Clear",
        prependAction:"prepend Action",
        appendAction:"append Action",

    },
    carousel:{
        next:"next",
        prev:"prev",

    }
  };
  
  export default ar;
  