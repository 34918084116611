/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import ar from '../locales/ar';
import en from '../locales/en';
// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'light',
  },
  locale: {
    locale: 'ar',
    fallback: 'ar',
    messages: { ar, en },
    rtl: {
        ar: true,
    },
},

})
